import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRouter } from "next/router"
import styled from "styled-components"
import useSWR from "swr"
import { fetcher } from "network/"
import StoreProductItem from "components/store/storeProductItem"
import StoreProductItemList from "components/store/storeProductItemList"
import StoreHeader from "components/store/storeHeader"
import StoreDetailsModal from "components/store/storeDetailsModal"
import StoreProductDetailModal from "components/store/storeProductDetailModal"
import Header from "components/shared/header"
import * as actions from "context/actionTypes"
import {
  useGlobalDisptach,
  useGlobalState,
} from "context/GlobalContextProvider"
import SEO from "components/seo"
import LOGGER from "lib/logger"
import Loader from "react-loader-spinner"
import { Flex } from "components/layout/shared"
import { colors } from "styles/colors"
import { motion } from "framer-motion"
import { getCustomCheckoutData, getStore, getStores } from "database/firebase"
import { sluggedName } from "lib/utils"
import getStoreNameCache from "lib/getStoreNameCache"
import createStoreDataCache from "lib/createStoreDataCache"
import MaxWidthContainer from "components/shared/maxWidthContainer"
import { TRACKING_EVENTS, _trackEvent } from "lib/mixpanel"
import NewOrderConfirmationDialog from "components/store/newOrderDialog"
import useHeaderImage from "custom-hooks/useHeaderImage"
import StoreTurnedOffDisplay from "components/store/storeTurnedOffDisplay"
import CustomHeader from "components/custom-header"
import toast from "react-hot-toast"
import { HiOutlineShoppingCart } from "react-icons/hi"
import StoreTurnedOffModal from "components/store/storeTurnedOffModal"
import CurrencyControl from "components/currency-control"
import usePricing from "custom-hooks/usePricing"
import KOSWaitListRegistration from "components/store/kos-wait-list-registration"
import { KOS_COMPANY_ID } from "lib/constants"
import FirstTimePopup from "components/landing/first-time-popup"
import SearchModal from "components/search/search-modal"
import * as GlobalSearchModal from "components/landing/search-modal"
import BackToTop from "components/back-top-top"

const DEFAULT_CATEGORY = "all"
const FILENAME = "store/[store].js"

const setting = {
  position: { horizontal: "left", vertical: "bottom" },
  color: {
    theme: colors.base,
  },
  launcher: {
    position: "Left",
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    ticketForms: [{ id: 1500001185561 }],
  },
}

const parseJSON = (inputString, fallback) => {
  if (inputString) {
    try {
      return JSON.parse(inputString)
    } catch (e) {
      return fallback
    }
  }
}

const StorePage = ({ storeData }) => {
  const storeDetails = parseJSON(storeData, {})
  const isCustomCheckout = storeDetails?.customCheckoutEnabled ?? false

  const router = useRouter()
  const { currentCurrency, setCurrency, currencyCodes } = usePricing()

  const { id, productId } = router.query
  const { data, error } = useSWR(
    `/api/store/products/${storeDetails.id}?currency=${currentCurrency}`,
    fetcher
  )
  const { data: storeCategories, error: categoriesError } = useSWR(
    `/api/store-categories/${storeDetails.id}`,
    fetcher
  )

  const { data: fetchedStoreDetails, error: storeDetailsError } = useSWR(
    `/api/store/${storeDetails.id}`,
    fetcher
  )

  //Refs
  const categoryMenuRef = useRef()

  // Memos
  const inStockProducts = useMemo(() => {
    return data?.products?.filter(product => product.inStock)
  }, [data])

  //State
  const [openProductDetails, setOpenProductDetails] = useState(false)
  const [openSearchModal, setOpenSearchModal] = useState(false)
  const [openGlobalSearchModal, setOpenGlobalSearchModal] = useState(false)
  const [openStoreDetails, setOpenStoreDetails] = useState(false)
  // const [selectedItem, setSelectedItem] = useState(null)
  const [freeAddons, setFreeAddons] = useState([])
  const [premiumAddons, setPremiumAddons] = useState([])
  const [photoGallery, setPhotoGallery] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY)
  const [newOrderDialogOpen, setNewOrderDialogOpen] = useState(false)
  const [newItemToAdd, setNewItemToAdd] = useState(null)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const [variations, setVariations] = useState([])
  const [loadingVariations, setLoadingVariations] = useState(false)
  const [waitListModalOpen, setWaitListModalOpen] = useState(false)
  const [waitListProduct, setWaitListProduct] = useState(null)
  const [categoryToScrollTo, setCategoryToScrollTo] = useState(null)

  const { currentStoreDetails, shoppingCart, discount } = useGlobalState()
  const dispatch = useGlobalDisptach()

  const selectedItem = useMemo(() => {
    return data?.products.find(product => product.id === productId) ?? null
  }, [data?.products, productId])

  //Custom Hooks
  const placeholderHeaderImage = useHeaderImage(
    storeDetails.categories ? storeDetails.categories[0] : ""
  )

  const itemClicked = item => {
    // setSelectedItem(item)
    router.push(
      `/store/${storeDetails.storeSlugName}?productId=${item.id}`,
      undefined,
      {
        shallow: true,
      }
    )
    _trackEvent(TRACKING_EVENTS.PRODUCT_VIEWED, {
      title: item.title,
      price: item.salePrice ? item.salePrice : item.price,
      store: currentStoreDetails.name,
    })
  }

  const handleOpenSearchModal = () => {
    setOpenSearchModal(!openSearchModal)
  }

  const handleStartSearch = () => {
    setOpenSearchModal(true)
    setCategoryToScrollTo(null)
  }

  const handleProductModalClose = () => {
    // setSelectedItem(null)
    setPhotoGallery([])
    setFreeAddons([])
    setPremiumAddons([])

    requestAnimationFrame(() => {
      router.replace(`/store/${storeDetails.storeSlugName}`, undefined, {
        shallow: true,
      })
    })
  }

  // Store details fetched, update the global dispatch
  useEffect(() => {
    if (
      fetchedStoreDetails &&
      currentStoreDetails &&
      !currentStoreDetails.mergedWithFetch
    ) {
      dispatch({
        type: actions.UPDATE_CURRENT_STORE_DETAILS,
        item: {
          ...currentStoreDetails,
          ...fetchedStoreDetails,
          mergedWithFetch: true,
        },
      })
    }
  }, [currentStoreDetails, fetchedStoreDetails])

  // Set the default currency to TTD if the store is not set to use custom checkout.
  // Remove this when currency codes are a global feature.
  useEffect(() => {
    if (
      currentStoreDetails.id !== KOS_COMPANY_ID &&
      currentCurrency !== currencyCodes.TTD
    ) {
      setCurrency(currencyCodes.TTD)
    }
  }, [currentStoreDetails])

  useEffect(() => {
    _trackEvent(TRACKING_EVENTS.STORE_VISITED, {
      name: storeDetails.name,
      source: "web",
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_STORE_DELIVERY_FEE,
      item: storeDetails.deliveryFee,
    })

    dispatch({
      type: actions.UPDATE_CURRENT_STORE_DETAILS,
      item: storeDetails,
    })
  }, [])

  useEffect(() => {
    getVariations()
    getAddsOn()
    getImageGallery()
  }, [selectedItem])

  // useEffect(() => {
  //   //If the product Id exists and no product is selected or no modal, find and show it
  //   if (data && data.products && productId && !selectedItem) {
  //     const product = data?.products.find(product => product.id === productId)
  //     setSelectedItem(product)
  //     _trackEvent(TRACKING_EVENTS.PRODUCT_VIEWED, {
  //       title: product.title,
  //       price: product.salePrice ? product.salePrice : product.price,
  //       store: currentStoreDetails.name,
  //     })
  //   }
  // }, [data, productId, selectedItem])

  useEffect(() => {
    dispatch({
      type: actions.SET_CUSTOM_CHECKOUT,
      item: storeDetails ? storeDetails?.customCheckoutEnabled : false,
    })
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (shoppingCart && shoppingCart.length > 0) {
      //Clear the cart if the store being selected is not the one being
      //accessed
      if (shoppingCart[0].storeId !== storeDetails.id) {
        dispatch({ type: actions.CLEAR_CART })
      }
    }
  }, [])

  useEffect(() => {
    const Helpkit = typeof window !== "undefined" ? window?.Helpkit : null
    if (!Helpkit) return
    if (selectedItem) {
      Helpkit.hideLaunchButton()
    } else {
      Helpkit.showLaunchButton()
    }
  }, [selectedItem])

  const handleScrollToCategory = useCallback(id => {
    setOpenSearchModal(false)
    setCategoryToScrollTo(`${id}`)
  }, [])

  const getAddsOn = async () => {
    if (!selectedItem) return

    const response = await fetch(
      `/api/store/products/${storeDetails.id}/addons?categoryId=${selectedItem.categoryId}&productId=${selectedItem.id}`
    )
    const data = await response.json()

    setFreeAddons(data.freeAddons.filter(addon => !addon.hide))
    setPremiumAddons(data.premiumAddons.filter(addon => !addon.hide))
  }

  const getVariations = async () => {
    if (!selectedItem) return
    setLoadingVariations(true)

    const response = await fetch(
      `/api/store/products/${storeDetails.id}/variations?categoryId=${selectedItem.categoryId}&productId=${selectedItem.id}`
    )
    const data = await response.json()
    setVariations(data.variations)
    setLoadingVariations(false)
  }

  const getImageGallery = async () => {
    if (!selectedItem) return
    const response = await fetch(
      `/api/products/${storeDetails.id}/photo-gallery?categoryId=${selectedItem.categoryId}&productId=${selectedItem.id}`
    )
    const data = await response.json()
    setPhotoGallery(data.photos)
  }

  const handleAddToCart = item => {
    const METHOD_NAME = "handleAddToCart"

    LOGGER.Debug(FILENAME, METHOD_NAME, { cartItem: item })
    // discount will be removed if adding to cart from store page
    if (discount && discount.value > 0) {
      dispatch({
        type: actions.ADD_DISCOUNT_CODE,
        item: { value: 0 },
      })
    }

    if (shoppingCart.length !== 0) {
      // if the item in the cart has a different storeId to the current Store loaded reject the add
      if (shoppingCart[0].storeId !== currentStoreDetails.id) {
        //Trying to add items from a new store into cart, let's show them a dialog to confirm if they want to do this.
        setNewItemToAdd(item)
        setNewOrderDialogOpen(true)
        return
      }
    }

    dispatch({
      type: actions.ADD_ITEM_TO_CART,
      item,
    })

    toast(
      <button
        onClick={() => {
          toast.remove()
          router.push("/cart")
        }}
        style={{
          cursor: "pointer",
          background: "none",
          outline: "none",
          border: "none",
          color: colors.base,
        }}
      >
        <p style={{ fontSize: 18, margin: 0 }}>
          Added {item.title} to your cart{" "}
        </p>
      </button>,
      {
        icon: <HiOutlineShoppingCart size={22} />,
        style: {
          width: "100%",
        },
      }
    )
  }

  const handleAddNewItemToCart = item => {
    const METHOD_NAME = "handleAddToCart"

    LOGGER.Debug(FILENAME, METHOD_NAME, { cartItem: item })
    // discount will be removed if adding to cart from store page

    //Add the current store to global state
    dispatch({
      type: actions.UPDATE_CURRENT_STORE_DETAILS,
      item: storeDetails,
    })

    if (discount && discount.value > 0) {
      dispatch({
        type: actions.ADD_DISCOUNT_CODE,
        item: { value: 0 },
      })
    }

    dispatch({
      type: actions.ADD_ITEM_TO_CART,
      item,
    })

    toast(
      <button
        onClick={() => {
          toast.remove()
          router.push("/cart")
        }}
        style={{
          cursor: "pointer",
          background: "none",
          outline: "none",
          border: "none",
          color: colors.base,
        }}
      >
        <p style={{ fontSize: 18, margin: 0 }}>
          Added {item.title} to your cart{" "}
        </p>
      </button>,
      {
        icon: <HiOutlineShoppingCart size={22} />,
        style: {
          width: "100%",
        },
      }
    )
  }

  function handleScroll() {
    const scrollTop = window.pageYOffset

    if (scrollTop > 450) {
      if (showScrollToTop) {
        //Do NOthing, return
        return
      }

      setShowScrollToTop(true)
    } else {
      setShowScrollToTop(false)
    }
  }

  function handleScrollToTop() {
    window.scrollTo(0, 0)
  }

  if (!currentStoreDetails.storeOn || !currentStoreDetails.active) {
    return (
      <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <SEO
          title={
            selectedItem
              ? `${selectedItem.title} - Shop ${storeDetails.name}`
              : `Shop ${storeDetails.name}`
          }
          description={
            selectedItem && selectedItem.description
              ? `${selectedItem.description}. Shop from ${storeDetails.name} and over 100 more stores on Unqueue!`
              : `Shop from ${storeDetails.name} and over 100 more stores on Unqueue!`
          }
          image={storeDetails?.headerImage ?? null}
        />
        {isCustomCheckout ? (
          <CustomHeader
            logo={storeDetails.logo}
            backgroundColor={storeDetails.headerBackgroundColor}
            textColor={storeDetails.headerTextColor}
          />
        ) : (
          <Header />
        )}
        <StoreTurnedOffModal
          companyId={storeDetails.id}
          note={storeDetails?.turnedOffSummary?.note}
          open={true}
          reOpenDate={storeDetails?.turnedOffSummary?.date}
        />
      </Container>
    )
  }

  return (
    <MaxWidthContainer size="lg">
      <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <SEO
          title={
            selectedItem
              ? `${selectedItem.title} - Shop ${storeDetails.name}`
              : `Shop ${storeDetails.name}`
          }
          description={
            selectedItem && selectedItem.description
              ? `${selectedItem.description}. Shop from ${storeDetails.name} and over 100 more stores on Unqueue!`
              : `Shop from ${storeDetails.name} and over 100 more stores on Unqueue!`
          }
          image={storeDetails?.headerImage ?? null}
        />
        {isCustomCheckout ? (
          <CustomHeader
            logo={storeDetails.logo}
            backgroundColor={storeDetails.headerBackgroundColor}
            textColor={storeDetails.headerTextColor}
            onClick={handleStartSearch}
          />
        ) : (
          <Header onClick={() => setOpenSearchModal(true)} />
        )}
        <StoreHeader
          companyId={id}
          customCheckout={isCustomCheckout}
          handleOnClick={setOpenStoreDetails}
          placeholderHeaderImage={placeholderHeaderImage}
          handleOpenSearchModal={handleOpenSearchModal}
          {...storeDetails}
        />
        <div ref={categoryMenuRef} />
        {window &&
          (window?.location?.host.includes("namdevco") ||
            window?.location?.pathname.includes("namdevco")) && (
            <FirstTimePopup />
          )}
        {/* <FirstTimePopup /> */}
        <Content>
          {data && storeCategories ? (
            storeCategories.map(category => (
              <StoreProductItemList
                categoryToScrollTo={categoryToScrollTo}
                isCustomCheckout={isCustomCheckout}
                key={category.id}
                data={data.products
                  .filter(product => product.inStock)
                  .filter(product => product.categoryId === category.id)}
                handleOnClick={item => itemClicked(item)}
                ProductComponent={StoreProductItem}
              />
            ))
          ) : (
            <Flex align="center" justify="center" style={{ height: "30vh" }}>
              <Loader type="Oval" color={colors.base} height={30} width={30} />
            </Flex>
          )}
        </Content>

        {storeDetails && !storeDetails.error ? (
          <StoreDetailsModal
            handleOnClose={setOpenStoreDetails}
            open={openStoreDetails}
            store={storeDetails}
          />
        ) : null}

        {selectedItem && productId ? (
          <StoreProductDetailModal
            checkoutEnabled={true}
            freeAddons={freeAddons}
            handleAddToCart={handleAddToCart}
            handleOnClose={handleProductModalClose}
            open={productId}
            product={selectedItem}
            premiumAddons={premiumAddons}
            photoGallery={photoGallery}
            storeId={storeDetails?.id}
            variations={variations}
            loadingVariations={loadingVariations}
            handleWaitListRegistration={product => {
              _trackEvent(TRACKING_EVENTS.BOOK_RESERVE_BUTTON_CLICKED)
              setWaitListProduct(product)
              setWaitListModalOpen(true)
              handleProductModalClose()
            }}
          />
        ) : null}
        {newOrderDialogOpen && (
          <NewOrderConfirmationDialog
            open={newOrderDialogOpen}
            setOpen={setNewOrderDialogOpen}
            startNewOrder={handleAddNewItemToCart}
            newItemToAdd={newItemToAdd}
          />
        )}
        <SearchModal
          open={openSearchModal}
          setOpen={setOpenSearchModal}
          openGlobalSearchModal={() => setOpenGlobalSearchModal(true)}
          scrollToCategory={handleScrollToCategory}
          storeDetails={{ ...storeDetails }}
          categories={storeCategories}
          products={inStockProducts}
        />
        <GlobalSearchModal.default
          open={openGlobalSearchModal}
          setOpen={setOpenGlobalSearchModal}
        />
      </Container>
      {/* Controls current currency */}
      <CurrencyControl />
      <KOSWaitListRegistration
        open={waitListModalOpen}
        setOpen={setWaitListModalOpen}
        product={waitListProduct}
        storeId={currentStoreDetails.id}
      />
      {showScrollToTop && <BackToTop handleClickEvent={handleScrollToTop} />}
    </MaxWidthContainer>
  )
}

export default StorePage

export async function getStaticPaths() {
  //Get active stores
  const stores = await getStores()
  console.log(`Got ${stores.length} stores`)

  const paths = stores
    .filter(store => store.name)
    .filter(store => sluggedName(store.name))
    .map(store => ({
      params: { id: store.id, store: sluggedName(store.name) },
    }))

  // cache the props in a file we can access in getStaticProps
  await createStoreDataCache(paths.map(path => path.params))

  return { paths, fallback: false }
}

// This also gets called at build time
export async function getStaticProps({ params }) {
  //Get the store passed from getStaticPaths
  const { store } = params
  let serializedData = null
  // Wrap in a try catch to prevent "Failed to load static props" Nextjs error
  try {
    //Get the cache of store objects created at build time, these contain our IDs that we can use to fetch page data
    const storeNameCache = await getStoreNameCache()
    // console.log({storeNameCache})
    //Find the object in cache
    const cachedStoreData =
      storeNameCache[storeNameCache.map(obj => obj.store).indexOf(store)]

    //Get page data as normal
    const { id } = cachedStoreData
    const storeData = await getStore(id)

    const customCheckoutData = await getCustomCheckoutData(id)
    // If we get checkout data back, we want to remove some fields
    !customCheckoutData.error &&
      delete customCheckoutData.companyName &&
      delete customCheckoutData.companyId
    serializedData = JSON.stringify({
      ...storeData,
      ...(!customCheckoutData.error &&
        storeData.customCheckoutEnabled &&
        customCheckoutData),
      id,
      storeSlugName: store,
    })
  } catch (err) {
    console.log("Encountered an error in getStaticProps for store page")
    console.log(err)
  }
  // Pass post data to the page via props
  return { props: { storeData: serializedData } }
}
const Container = styled(motion.div)`
  background-color: #fff;
  padding-bottom: 15vh;
`
const Content = styled.div`
  padding: 30px;

  @media (max-width: 550px) {
    padding: 15px;
  }
`
