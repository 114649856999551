import { Flex } from 'components/layout/shared';
import Link from 'next/link';
import React from 'react'
import styled from 'styled-components';
import { colors } from 'styles/colors';

export default function StoreTurnedOffDisplay({ name }) {
    return (
        <Container justify="center" align="center" direction="column">
            <Title>Sorry</Title>
            <Copy>It seems like {name} store has been temporarily turned off. Please try again later.</Copy>
            <Link href="/">Go home</Link>
        </Container>
    )
}

const Container = styled(Flex)`
    text-align: center;
    height: 60vh;
    padding: 20px;
`;
const Title = styled.h1`
    font-family: "Cortado";
    font-size: 200px;
    line-height: 150%;
    color: ${colors.base};

    @media (max-width: 550px) {
        font-size: 150px;

    }
`;
const Copy = styled.p`
    max-width: 450px;
    color: #707070;
    font-size: 20px;
    line-height: 1.2;
`;