
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/store/[store]",
      function () {
        return require("private-next-pages/store/[store].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/store/[store]"])
      });
    }
  